<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('充值明细')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <!-- <van-pull-refresh pulling-text="Sao chép nhanh quy trình thả xuống" loosing-text="Bản sao nhắc nhở của quá trình phát hành" loading-text="Đang tải bản sao nhắc quá trình" success-text="Làm mới bản sao lời nhắc thành công" v-model="isLoading" @refresh="onRefresh"> -->
          <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
          <div v-else class="item_list" v-for="(v,key) in list" :key="key">
            <div class="topInfo">
              <span>{{$t('充值金额')}}：{{v.money}}</span>
              <span v-if="v.status === 0">{{$t('待审核')}}</span>
              <span v-else-if="v.status === 1">{{$t('已通过')}}</span>
              <span v-else>{{$t('已拒绝')}}</span>
            </div>
            <div class="time">
              <span>{{$t('充值时间')}}：{{v.create_time}}</span>
              <span>{{v.pay_time}}</span>
            </div>
            <!-- <div class="status">
              <span v-if="v.status === 0">{{$t('待审核')}}</span>
              <span v-else-if="v.status === 1">{{$t('已通过')}}</span>
              <span v-else>{{$t('已拒绝')}}</span>
            </div> -->
          </div>
      <!-- </van-pull-refresh> -->
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      list:[]
    };
  },
  mounted() {
    // this.getUserWithdrawList();
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList(){
      this.$http({
        method: 'get',
        url: 'upload_logs'
      }).then(res=>{
        console.log(res)
        console.log("########################")
       
          this.list = res.data.data;
          console.log("########################")
          console.log(this.list)
      
        //   this.$toast(res.msg);
        // }
      })
    }
  },
  created() {
    this.getUserWithdrawList();
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

</style>
