<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar
        :title="$t('钱包充值')"
        style="font-size: 30px; display: flex"
        class="nav-bar"
      >
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
        <template #right>
          <span
            class="nav-right"
            @click="$router.push({ path: '/WithdrawRecords' })"
            >{{ $t("充值明细") }}</span
          >
        </template>
      </van-nav-bar>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      "
    >
      <!-- <div
        class="content"
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <h1>Adddddd</h1>
        <div style="font-size: 30px">sssssssssssssssssssssss</div>
        <van-button
          type="warning"
          style="width: 150px; height: 50px; font-size: 24px"
          >警告按钮</van-button
        >
      </div> -->
      <div
        style="
          width: 100%;
          height: 500px;
          background-color: #fff;
          margin-top: 20px;
          padding: 2%;
        "
      >
        <div style="font-size: 24px">{{ $t("转账金额") }}</div>

        <input
        style="width: 100%;
    font-size: 28px;
    height: 65px;"
          type="text"
          id="myInput"
          v-model="money"
           :placeholder="$t('请输入转账金额')"
        />
        <div style="font-size: 24px; margin-top: 30px">
          {{ $t("上传截图") }}
        </div>
        <div style="width: 100%; margin-top: 20px">
          <van-uploader
            :max-count="3"
            style=""
            v-model="fileList"
            :after-read="afterRead"
          />
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <van-button
            style="width: 80%; height: 60px; font-size: 20px"
            type="info"
            @click="fontrm"
            >{{ $t("提交") }}</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import { uploadImage } from "@/http/upload";
export default {
  data() {
    return {
      image: "",
      money: null,
      fileList: [],
      value: "",
      message: "",
      activeNames: [1],
      items: [],
      config: {},
      selectedFile: null,
    };
  },
  created() {
    this.$http({
      method: "get",
      url: "banks_list",
    }).then((res) => {
      this.items = res.data;
    });
    this.$http({
      method: "get",
      url: "base_info",
    }).then((res) => {
      this.config = res.data;
    });
  },
  methods: {
    handleInputChange(newValue) {
      console.log("????????");
      this.money = newValue;
    },
    afterRead(files, detail) {
      console.log(files, detail);
      console.log("?????????????");
      files.forEach((element) => {
        // 模拟请求上传图片接口操作
        setTimeout(() => {
          this.selectedFile = element.file;
          if (!this.selectedFile) {
            alert("请选择一张图片！");
            return;
          }

          // const formData = new FormData();
          // formData.append('image', this.selectedFile);
          try {
            uploadImage(this.selectedFile)
              .then((result) => {
                this.image = result.data.path;
                console.log(result); // 输出: { data: '这是从服务器获取的数据' }
              })
              .catch((error) => {
                console.error("发生错误:", error);
              });
          } catch (error) {
            console.error("上传失败:", error);
            this.image = "上传失败";
          }
          element.status = null; // 上传成功后须将file对象的status属性置为null，否则遮罩不会消失。
        }, 2000);
      });
    },
    fontrm() {
      this.$http({
        method: "post",
        url: "upload_create",
        data: {
          money: this.money,
          image: "/uploads/20241124/aa47a8621951dc94fd847ad2946710cb.png",
        },
      }).then((res) => {
        console.log(res);
        this.$router.push({path:'/WithdrawRecords'})
      });
    },

    back() {
      return window.history.back();
    },
    toServicePage() {
      const service = this.$store.getters.getBaseInfo;
      console.log(service);
      if (service.iskefu == 1) {
        console.log("ssss");
        window.location.href = service.kefu;
      }
      // this.$router.push("ServicePage");
    },
  },
};
</script>

<style lang="less">
.van-uploader__preview {
  width: 200px !important;
  height: 200px !important;
}
.van-uploader__preview-delete {
  width: 30px !important;
  height: 30px !important;
}
.van-uploader__preview-image {
  width: 200px !important;
  height: 200px !important;
}
.van-uploader__upload {
  width: 200px !important;
  height: 200px !important;
}
.van-uploader__wrapper {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;

  height: 200px !important;
}
.van-uploader__file {
  width: 200px !important;
  height: 200px !important;
}
.van-nav-bar__title {
  font-size: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
