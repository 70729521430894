// src/api/upload.js
import axios from 'axios';

const uploadInstance = axios.create({
  baseURL: ' https://soulmeetlove.com/api/common/uploadImage', // 替换为你的 API 基础 URL
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'multipart/form-data', // 确保内容类型为 multipart/form-data
  },
});

// 添加请求拦截器（可选）
uploadInstance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，比如添加 token
    // config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器（可选）
uploadInstance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    console.log(response.data)
    return response.data;
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('image', file);

  return uploadInstance.post('/upload-endpoint', formData, {
    headers: {
      ...uploadInstance.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};